<template>
    <div class="trading-right">
        <div class="tab-list1">
            <div v-for="(item,index) in tabList1" 
            :key="index" 
            :class="['tab-item', curIndex1 == index ? 'active' : '']" 
            @click="changeCurIndex1(index)">
                {{item}}
            </div>
        </div>
        <div v-if="curIndex1 == 0" class="trading-pane">
            <div class="tab-list2">
                <el-tooltip v-for="(item,index) in tabList2" :key="index" effect="dark" :content="item.title" placement="bottom">
                    <img class="icon" :src="curIndex2 == index ? item.activeIcon : item.icon" @click="changeCurIndex2(index)" />
                </el-tooltip>
            </div>
            <div class="pane-table">
                <div class="tr">
                    <div class="th">{{$t('Trade.Price')}}</div>
                    <div class="th">{{$t('Trade.Quantity')}}</div>
                    <div class="th">{{$t('Trade.Total')}}</div>
                </div>
                <el-tooltip v-if="curIndex2 != 2" effect="dark" placement="left-start">
                     <div slot="content" class="cal-list">
                         <div class="cal-item">
                             <div class="label">{{$t('Trade.AveragePrice')}}</div>
                             <div class="value">{{bidsCal.av_price}}</div>
                         </div>
                         <div class="cal-item">
                             <div class="label">{{$t('Trade.Total')}} {{curCoin.name}}</div>
                             <div class="value">{{bidsCal.coin_sum}}</div>
                         </div>
                         <div class="cal-item">
                             <div class="label">{{$t('Trade.Total')}} USDT</div>
                             <div class="value">{{bidsCal.usdt_sum}}</div>
                         </div>
                     </div>
                     <div class="up-box" @mouseleave="leave1">
                         <div v-for="(item,index) in bids" 
                         :key="index" 
                         class="tr" 
                         @mouseenter="enter1(index)" 
                         :style="{ backgroundColor: upActiveIndex >= index ? '#F3F5FF' : '' }">
                             <div class="td up-color">{{item[0]}}</div>
                             <div class="td">{{item[1]}}</div>
                             <div class="td">{{item[2]}}</div>
                             <div class="per-bg" :style="{ width: (item[1] / buymax) * 100 + '%', backgroundColor: upBgColor }"></div>
                         </div>
                     </div>
                </el-tooltip>
                <div class="currency-price">
                    <span class="price" :style="{color: parseFloat(curCoin.price.close) >= parseFloat(curCoin.price.open) ? '#01BD8B' : '#FB474E'}">{{parseFloat(curCoin.price.close)}}</span>
                    ≈ {{parseFloat(curCoin.price.close)}}$
                </div>
                <el-tooltip v-if="curIndex2 != 1" effect="dark" placement="left-start">
                     <div slot="content" class="cal-list">
                         <div class="cal-item">
                             <div class="label">{{$t('Trade.AveragePrice')}}</div>
                             <div class="value">{{asksCal.av_price}}</div>
                         </div>
                         <div class="cal-item">
                             <div class="label">{{$t('Trade.Total')}} {{curCoin.name}}</div>
                             <div class="value">{{asksCal.coin_sum}}</div>
                         </div>
                         <div class="cal-item">
                             <div class="label">{{$t('Trade.Total')}} USDT</div>
                             <div class="value">{{asksCal.usdt_sum}}</div>
                         </div>
                     </div>
                     <div class="down-box" @mouseleave="leave2">
                         <div v-for="(item,index) in asks" 
                         :key="item.id" 
                         class="tr" 
                         @mouseenter="enter2(index)" 
                         :style="{ backgroundColor: downActiveIndex >= index ? '#F3F5FF' : '' }">
                             <div class="td down-color">{{item[0]}}</div>
                             <div class="td">{{item[1]}}</div>
                             <div class="td">{{item[2]}}</div>
                             <div class="per-bg" :style="{ width: (item[1] / sellmax) * 100 + '%', backgroundColor: downBgColor }"></div>
                         </div>
                     </div>
                </el-tooltip>
            </div>
        </div>
        <div v-else class="latest-records">
            <div class="tr">
                <div class="th">{{$t('Trade.Time')}}</div>
                <div class="th">{{$t('Trade.Price')}}</div>
                <div class="th">{{$t('Trade.Quantity')}}</div>
            </div>
            <div v-for="(item,index) in latestRecord" :key="index" class="tr">
                <div class="td">{{item.created_at.split(' ')[1]}}</div>
                <div class="td" :style="{color: item.side == 'BUY' ? '#01BD8B' : '#FB474E'}">{{parseFloat(item.price).toFixed(curCoin.priceDecimal)}}</div>
                <div class="td">{{parseFloat(item.number).toFixed(curCoin.numberDecimal)}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tabList1: [this.$t('Trade.OrderBook'), this.$t('Trade.LatestRecord')],
                tabList2: [
                    {
                        title: this.$t('Trade.All'),
                        icon: require('@/assets/images/pc/trade/all.png'),
                        activeIcon: require('@/assets/images/pc/trade/all-active.png')
                    },
                    {
                        title: this.$t('Trade.Bids'),
                        icon: require('@/assets/images/pc/trade/buyup.png'),
                        activeIcon: require('@/assets/images/pc/trade/buyup-active.png')
                    },
                    {
                        title: this.$t('Trade.Asks'),
                        icon: require('@/assets/images/pc/trade/buydown.png'),
                        activeIcon: require('@/assets/images/pc/trade/buydown-active.png')
                    }
                ],
                curIndex1: 0,
                curIndex2: 0,
                upData: [],
                downData: [],
                upActiveIndex: -1,
                downActiveIndex: -1,
                upBgColor: 'rgba(1,189,139,.15)',
                downBgColor: 'rgba(251,71,78,.15)'
            }
        },
        created() {
            // console.log('created')
            this.$store.commit('setCurTime','1min')
        },
        destroyed() {
            // console.log('destroyed')
            this.$ws.sendMsg(`{"type":"leave","data":"kline:${this.curCoin.key}:${this.curTime}"}`)
        },
        computed: {
            connect() {
                return this.$store.state.connect
            },
            
            randomPercent() {
                return (Math.random()*100)+1
            },
            
            curCoin() {
                return this.$store.state.curCoin
            },
            
            orderBook() {
                return this.$store.state.orderBook
            },
            
            bids() {
                let bids = this.orderBook.bids.slice(0,20)
                bids.map(item => {
                    item[0] = parseFloat(item[0]).toFixed(this.curCoin.priceDecimal)
                    item[1] = parseFloat(item[1]).toFixed(this.curCoin.numberDecimal)
                    item[2] = (parseFloat(item[0]) * parseFloat(item[1])).toFixed(this.curCoin.priceDecimal)
                })
                return bids
            },
            
            asks() {
                let asks = this.orderBook.asks.slice(0,20)
                asks.map(item => {
                    item[0] = parseFloat(item[0]).toFixed(this.curCoin.priceDecimal)
                    item[1] = parseFloat(item[1]).toFixed(this.curCoin.numberDecimal)
                    item[2] = (parseFloat(item[0]) * parseFloat(item[1])).toFixed(this.curCoin.priceDecimal)
                })
                return asks
            },
            
            buymax() {
              let buys = this.bids.map(item => +item[1])
              return Math.max.apply(null, buys) || 0
            },
            
            sellmax() {
              let sells = this.asks.map(item => +item[1])
              return Math.max.apply(null, sells) || 0
            },
            
            bidsCal() {
                let price_sum = 0
                let cal = {
                    av_price: 0,
                    coin_sum: 0,
                    usdt_sum: 0
                }
                this.bids.forEach((item,index) => {
                    if(this.upActiveIndex >= index) {
                        price_sum += parseFloat(item[0])
                        cal.coin_sum += parseFloat(item[1])
                        cal.usdt_sum += parseFloat(item[2])
                    }
                })
                cal.av_price = this.upActiveIndex >= 0 ? (price_sum / (this.upActiveIndex + 1)).toFixed(this.curCoin.priceDecimal) : 0
                cal.coin_sum = parseFloat(cal.coin_sum).toFixed(this.curCoin.numberDecimal)
                cal.usdt_sum = parseFloat(cal.usdt_sum).toFixed(this.curCoin.priceDecimal)
                
                return cal
            },
            
            asksCal() {
                let price_sum = 0
                let cal = {
                    av_price: 0,
                    coin_sum: 0,
                    usdt_sum: 0
                }
                this.asks.forEach((item,index) => {
                    if(this.downActiveIndex >= index) {
                        price_sum += parseFloat(item[0])
                        cal.coin_sum += parseFloat(item[1])
                        cal.usdt_sum += parseFloat(item[2])
                    }
                })
                cal.av_price = this.downActiveIndex >= 0 ? (price_sum / (this.downActiveIndex + 1)).toFixed(this.curCoin.priceDecimal) : 0
                cal.coin_sum = parseFloat(cal.coin_sum).toFixed(this.curCoin.numberDecimal)
                cal.usdt_sum = parseFloat(cal.usdt_sum).toFixed(this.curCoin.priceDecimal)
                
                return cal
            },
            
            latestRecord() {
                return this.$store.state.latestRecord.slice(0,40)
            },
            
            curTime() {
                return this.$store.state.curTime
            }
        },
        watch: {
            curCoin(new_val,old_val) {
                // console.log(new_val.key,old_val.key)
                if(!old_val.key || old_val.key == new_val.key) {
                    this.$store.dispatch('getOrderBook',new_val.key)
                    this.$store.dispatch('getLatestRecord',new_val.key) 
                }
                if(old_val.key) {
                    this.$ws.sendMsg(`{"type":"leave","data":"depth:${old_val.key}"}`)
                    this.$ws.sendMsg(`{"type":"leave","data":"trade:${old_val.key}"}`)
                    this.$ws.sendMsg(`{"type":"leave","data":"kline:${old_val.key}:${this.curTime}"}`)
                }
                this.$ws.sendMsg(`{"type":"join","data":"depth:${new_val.key}"}`)
                this.$ws.sendMsg(`{"type":"join","data":"trade:${new_val.key}"}`)
                this.$ws.sendMsg(`{"type":"join","data":"kline:${new_val.key}:${this.curTime}"}`)
            },
            
            connect(value) {
                let val = JSON.parse(value)
                if(val.status) {
                    if(this.curCoin.key && val.type != 'first') {
                        this.$ws.sendMsg(`{"type":"join","data":"depth:${this.curCoin.key}"}`)
                        this.$ws.sendMsg(`{"type":"join","data":"trade:${this.curCoin.key}"}`)
                        this.$ws.sendMsg(`{"type":"join","data":"kline:${this.curCoin.key}:${this.curTime}"}`)
                    }
                }
            }
        },
        methods: {
            changeCurIndex1(index) {
                this.curIndex1 = index
            },
            
            changeCurIndex2(index) {
                this.curIndex2 = index
            },
            
            enter1(index) {
                this.upActiveIndex = index
            },
            
            leave1() {
                this.upActiveIndex = -1
            },
            
            enter2(index) {
                this.downActiveIndex = index
            },
            
            leave2() {
                this.downActiveIndex = -1
            }
        }
    }
</script>

<style lang="scss" scoped>
    .trading-right {
        min-width: 340px;
        max-width: 340px;
        box-sizing: border-box;
        padding: 0 0 8px;
        background-color: #FFFFFF;
        overflow: auto;
        
        .tab-list1 {
            display: flex;
            align-items: center;
            width: 100%;
            height: 42px;
            box-sizing: border-box;
            padding: 4px 14px 0;
            border-bottom: 1px solid #D3D3D3;
            
            .tab-item {
                font-size: 15px;
                color: #6D6F7E;
                white-space: nowrap;
                cursor: pointer;
                
                &:not(:last-child) {
                    margin-right: 22px;
                }
            }
            
            .active {
                font-weight: bold;
                color: #000000;
            }
        }
        
        .trading-pane {
            width: 100%;
            
            .tab-list2 {
                display: flex;
                align-items: center;
                width: 100%;
                height: 52px;
                box-sizing: border-box;
                padding: 0 14px;
                
                .icon {
                    min-width: 28px;
                    max-width: 28px;
                    height: 28px;
                    cursor: pointer;
                    
                    &:not(:last-child) {
                        margin-right: 16px;
                    }
                }
            }
            
            .pane-table {
                width: 100%;
                
                .up-box {
                    width: 100%;
                }
                
                .down-box {
                    width: 100%;
                }
                
                .tr {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 16px;
                    cursor: default;
                    
                    .per-bg {
                        position: absolute;
                        right: 0;
                        height: 100%;
                        transition: all 0.2s;
                    }
                    
                    .th {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        line-height: 120%;
                        box-sizing: border-box;
                        padding: 5px 0;
                        font-size: 14px;
                        color: #6D6F7E;
                        text-align: center;
                        word-wrap: break-word;
                        
                        &:first-child {
                            justify-content: flex-start !important;
                        }
                        
                        &:last-child {
                            justify-content: flex-end !important;
                        }
                    }
                    
                    .td {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 5px 0;
                        font-size: 15px;
                        color: #000000;
                        text-align: center;
                        word-break: break-all;
                        
                        &:first-child {
                            justify-content: flex-start !important;
                        }
                        
                        &:nth-last-child(2) {
                            justify-content: flex-end !important;
                        }
                    }
                    
                    .up-color {
                        color: #01BD8B !important;
                    }
                    
                    .down-color {
                        color: #FB474E !important;
                    }
                }
                
                .currency-price {
                    width: 100%;
                    height: 48px;
                    line-height: 48px;
                    box-sizing: border-box;
                    padding: 0 16px;
                    font-size: 18px;
                    color: #6D6F7E;
                    background-color: #F2F2F2;
                    cursor: default;
                    
                    .price {
                        margin-right: 8px;
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
            }
        }
        
        .latest-records {
            width: 100%;
            box-sizing: border-box;
            padding-top: 12px;
            
            .tr {
                display: flex;
                justify-content: space-between;
                width: 100%;
                cursor: default;
                
                .th {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 120%;
                    box-sizing: border-box;
                    padding: 6px 0;
                    font-size: 14px;
                    color: #6D6F7E;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .td {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 6px 0;
                    font-size: 15px;
                    color: #000000;
                    text-align: center;
                    word-break: break-all;
                }
            }
        }
    }
</style>